<div class="container-fluid px-5 py-4">

    <div class="row text-center my-5">
        <div class="col-12 col-lg-4">
            <h1>Simple à offrir</h1>
        </div>
        <div class="d-none d-lg-block col-lg-8">
            <h1>Simple à consommer</h1>
        </div>
    </div>

    <div class="row g-0">

        <div class="col-12 col-lg-4 text-center d-flex flex-column pb-5">
            <div class="my-auto px-5">
                <p class="mt-5">
                    Il vous suffit de saisir une donnée<br>
                    (ex : numéro de téléphone) de votre
                    client pour que celui-ci soit récompensé*<br>
                </p>
                <p class="small font-italic">*Sous réserve qu'il ait accompli un acte éligible</p>
            </div>
            <div class="my-auto"></div>
        </div>

        <div class="col-12 col-lg-8">
            <div class="border-left">

                <h2 class="d-lg-none my-5">Pour votre client </h2>

                <div class="row">
                    <div class="col-12 col-md-4 px-2 my-auto">
                        Votre client reçoit ses accès par email et/ou sms
                    </div>
                    <div class="col-12 col-md-8 px-3 text-center text-purple">
                        <div class="px-5 d-flex flex-row justify-content-around">
                            <div class="ms-5 ps-3">
                                <img alt="icon" class="icon" height="" src="../../../../assets/icons/email-purple.svg" width="">
                            </div>
                            <div class="me-5 pe-3">
                                <img alt="sms icon" class="icon" height="" src="../../../../assets/icons/sms-purple.svg" width="">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-4"></div>
                    <div class="col-12 col-md-8 text-center">
                        <i aria-hidden="true" class="fa-2x fas fa-caret-down text-purple"></i>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-4 px-2 my-auto">
                        Il choisit les codes cadeaux qui lui conviennent sur son espace personnel de récompense
                    </div>
                    <div class="col-12 col-md-8 px-5 text-center">
                        <img [src]="'https://cdn.isifid.com/gift/img/howto/screenshot-partners.jpg'"
                             alt="screenshot 1" height="auto" width="70%">
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-4"></div>
                    <div class="col-12 col-md-8 text-center">
                        <i aria-hidden="true" class="fa-2x fas fa-caret-down text-purple"></i>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-4 px-2">
                        Il se rend sur les sites des partenaires, commande et règle avec ses codes cadeaux
                    </div>
                    <div class="col-12 col-md-8 px-5 text-center">
                        <img [src]="'https://cdn.test.isifid.com/gift/img/howto/screenshot-order.png'"
                             alt="screenshot 2" height="auto" width="70%">
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
