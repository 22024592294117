@if (consumer) {
    <div class="container-fluid m-0 py-3 py-md-5">
        <div class="row justify-content-center">

            <div class="col-12 col-lg-4 border-right text-center">

                <form [formGroup]="consumerForm">

                    <div class="row justify-content-center">
                        <div class="col-7">
                            <img alt="user icon"
                                 class="pb-4"
                                 height="auto"
                                 src="assets/icons/user-circle-2.svg"
                                 width="60">
                            <h2 class="my-3 text-dark-blue">
                                {{ consumer.firstName | titlecase }} {{ consumer.lastName | titlecase}}
                            </h2>
                        </div>
                        <div class="col-1"></div>
                    </div>

                    @if (sponsorshipService.hasSponsorship) {
                        <div class="row justify-content-center">
                            <div class="col-7 mb-4">
                                @if (isSponsor() && !isActiveSponsor()) {
                                    <span>
                                        Parrain Potentiel
                                        <em [matTooltipPosition]="'below'"
                                            [matTooltip]="'Client inscrit sur la plateforme de recommandation et n’a pas encore obtenu de filleul'"
                                            class="fas fa-question-circle text-purple"></em>
                                        <br>
                                        Code parrain : {{ getSponsorCode() }}
                                    </span>
                                }
                                @if (isSponsor() && isActiveSponsor()) {
                                    <span>
                                        Parrain
                                        <em [matTooltipPosition]="'below'"
                                            [matTooltip]="'Client qui a déjà parrainé avec succès un ou plusieurs de ses proches'"
                                            class="fas fa-question-circle text-purple"></em>
                                        <br>
                                        Code parrain : {{ getSponsorCode() }}
                                    </span>
                                } @else if (!isSponsor() && isSponsored()) {
                                    <span>
                                        Filleul
                                        <em [matTooltipPosition]="'below'"
                                            [matTooltip]="'Client qui a ouvert un compte à la suite de la recommandation d’un proche'"
                                            class="fas fa-question-circle text-purple"></em>
                                    </span>
                                }
                            </div>
                            <div class="col-1"></div>
                        </div>
                    }

                    <div class="row justify-content-center">
                        <div class="col-8">
                            <mat-form-field appearance="fill">
                                <mat-label>Email</mat-label>
                                <input matInput
                                       formControlName="email"
                                       id="email"
                                       type="email"/>
                            </mat-form-field>
                        </div>
                        <div class="col-8 mt-1 mb-50">
                            <span (click)="saveConsumerField('email')" class="btn btn-validate clickable">
                                Mettre à jour
                            </span>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-8">
                            <mat-form-field appearance="fill">
                                <mat-label>N° de portable</mat-label>
                                <input matInput
                                       formControlName="mobile"
                                       id="mobile"
                                       type="tel"/>
                            </mat-form-field>
                        </div>
                        <div class="col-8 mt-1 mb-50">
                            <span (click)="saveConsumerField('mobile')" class="btn btn-validate clickable" data-cy="save-mobile">
                                Mettre à jour
                            </span>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-12 col-lg-4 text-center">
                <img alt="tools icon"
                     class="pb-4"
                     height=""
                     src="assets/icons/gift.svg"
                     width="60">
                <h2 class="my-3 text-dark-blue">Récompenses </h2>
                @for (reward of rewards; track reward.id) {
                    <div>
                        <div class="text-dark-blue fw-bold">{{ getOfferName(reward.offerId) }}</div>
                        <div class="lh-lg">
                            {{ reward.amount}}€ -
                            @if (!isCashReward(reward.rewardTypeId)) {
                                <span><a routerLink="/gift-cards">
                                    {{ uiService.getRewardType(reward.rewardTypeId) }}</a> -</span>
                            }
                            @if (hasExpired(reward.expiresAt)) {
                                <span>Expiré</span>
                            }
                            @if (!hasExpired(reward.expiresAt) && !isCashReward(reward.rewardTypeId)) {
                                <span>
                                    Valable jusqu'au {{ (reward.expiresAt | dateBefore: 1) | date:'dd/MM/yyyy' }}
                                </span>
                            }
                        </div>
                    </div>
                }
                @if (rewards.length === 0) {
                    <p class="my-5">Pas encore récompensé.</p>
                }
            </div>

        </div>

        <div class="row justify-content-center mt-5">
            @if (sponsorshipService.hasSponsorship && !isSponsor()) {
                <div class="col-lg-2 text-center">
                    @if (!checkAge && consumer.mobile && consumer.email && canDisplayBecomeSponsorButton()) {
                        <a (click)="createSponsorshipUser()"
                           class="clickable">
                            Inscrire comme parrain
                        </a>
                    }
                    @if (!consumer.mobile || !consumer.email) {
                        <span>
                            Merci de renseigner l'email et le N° de portable de votre client pour pouvoir l'inscrire comme parrain.
                        </span>
                    }
                    @if (checkAge) {
                        <form (ngSubmit)="submit()" [formGroup]="checkAgeForm">
                            <div class="d-flex">
                                <span class="pt-2">
                                    <input formControlName="checkAge" id="checkAge" required="true" type="checkbox">
                                </span>
                                <label class="ms-2 check-box" for="checkAge">
                                    L'âge de votre client est compris entre {{ sponsorshipService.settings.sponsorAgeMin }}
                                    et {{ sponsorshipService.settings.sponsorAgeMax }} ans.
                                </label>
                            </div>
                            <button (click)="submit()" class="btn btn-validate px-4 py-2 mt-2">Valider</button>
                        </form>
                    }
                </div>
            }

            @if (sponsorshipService.hasSponsorshipOnline && isSponsor()) {
                <div class="col-lg-2 text-center">
                    <a [href]="sponsorshipUrl" target="_blank">
                        Accéder au compte Parrainage du client
                    </a>
                </div>
                <div class="col-lg-2 text-center">
                    @if (!accessSent.sponsorship) {
                        <a (click)="sendSponsorAccess()" class="text-purple">
                            Renvoyer un accès aux compte Parrainage du client
                        </a>
                    } @else if (accessSent.sponsorship) {
                        <span>Accès au compte Parrainage du client envoyé</span>
                    }
                </div>
            }

            @if (!sponsorshipService.hasSponsorshipOnline && isSponsor()) {
                <div class="col-lg-2 text-center">
                    @if (!accessSent.sponsorship) {
                        <a (click)="sendSponsorAccess()" class="text-purple">Renvoyer le code
                            parrain</a>
                    } @else if (accessSent.sponsorship) {
                        <span>Email envoyé</span>
                    }
                </div>
            }

            @if (hasActiveReward() && !accessSent.marketplace && !uiService.rewardWithCash) {
                <div class="col-lg-2 text-center">
                    <a (click)="sendAccess()" class="text-purple">Renvoyer un accès aux récompenses</a>
                </div>
            }
            @if (accessSent.marketplace) {
                <div class="col-lg-2 text-center">
                    <span>Email et/ou sms d'accès renvoyé(s)</span>
                </div>
            }

            @if (rewards && rewards.length > 0) {
                <div class="col-lg-2 text-center">
                    <a routerLink="/support">Signaler un problème</a>
                </div>
            }
        </div>
    </div>
}
