<form class="text-center" [formGroup]="sponsorAgeForm" (ngSubmit)="beComeSponsor()">
    <p class="mt-5 mb-3 mx-auto">Voulez-vous faire du client un utilisateur de parrainage ?</p>
    <mat-checkbox formControlName="checkAge">
        @if (sponsorshipService.settings.sponsorAgeMax < 30) {
            Votre client a entre
            {{ sponsorshipService.settings.sponsorAgeMin || 18 }} et
            {{ sponsorshipService.settings.sponsorAgeMax || 120 }} ans.
        }
        @if (sponsorshipService.settings.sponsorAgeMax > 30) {
            Votre client a plus de {{ sponsorshipService.settings.sponsorAgeMin || 18 }} ans.
        }
    </mat-checkbox>
    <div>
        <button type="submit" [disabled]="sponsorAgeForm.controls.checkAge.invalid" class="btn btn-validate mt-2 min-w-228">
            @if (loading) {
                <em class="fas fa-spinner fa-spin"></em>
            } @else {
                Inscrire comme parrain
            }
        </button>
    </div>
</form>
