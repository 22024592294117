@if (loading) {
    <div class="w-100 text-center position-absolute top-20">
        <h1> Chargement </h1>
        <div class="fa-3x">
            <em class="fas fa-sync fa-spin"></em>
        </div>
    </div>
} @else {
    <div class="container-fluid p-0 m-0 min-vh-100">
        <mat-toolbar class="bg-white h-auto mb-5">
            <div class="col mx-auto">
                <img [src]="settings.clientLogoUrl" alt="logo" class="logo d-block mx-auto my-4" height="auto" width="auto">
            </div>
        </mat-toolbar>

        <div class="container mx-auto pb-3">
            @if (isComingFromLogout) {
                <div class="py-3 text-center">
                    <mat-card class="text-center">
                        <mat-card-content>
                            Vous avez été déconnecté(e) du service
                        </mat-card-content>
                    </mat-card>
                </div>
            }
            <div class="row">
                <div class="col-12 col-md h-100 my-3 my-md-0">
                    <mat-card class="p-0">
                        <mat-card-header class="px-4 px-lg-5 pt-5 pb-3 text-white">
                            <mat-card-title class="fw-bold big pb-2">SE CONNECTER</mat-card-title>
                            @if (!accessSent && !inviteOnly) {
                                <mat-card-subtitle class="text-white pe-5">
                                    Afin d’accéder à la plateforme de récompense veuillez renseigner votre adresse email. Vous
                                    recevrez un lien unique de connexion.
                                </mat-card-subtitle>
                            }
                        </mat-card-header>
                        @if (accessSent) {
                            <mat-card-content class="p-5">
                                <h2 class="text-dark py-5 my-5 text-align-justify">
                                    Votre accès a bien été envoyé par email. En raison des contraintes de sécurité, vous devriez
                                    le recevoir d'ici quelques minutes, pensez à vérifier votre dossier spam.
                                    <br><br>
                                    Une fois connecté(e), vous pourrez mettre ce lien en favori (cf processus décrit dans
                                    l'email) pour ne plus avoir à vous connecter par email.
                                </h2>
                            </mat-card-content>
                        } @else {
                            <mat-card-content class="px-3 py-5">
                                @if (!inviteOnly) {
                                    <form (ngSubmit)="getAccess()" [formGroup]="loginForm"
                                          class="container mt-3 mb-5 px-5 mx-auto text-center">
                                        <div class="row my-3">
                                            <mat-form-field appearance="fill" class="col">
                                                <mat-label>Email</mat-label>
                                                <input matInput
                                                       formControlName="email"
                                                       id="email"
                                                       maxlength="60"
                                                       type="email"/>
                                                <mat-error>
                                                    @if (!loginForm.controls.email.hasError('email')) {
                                                        <span>
                                                            Merci de saisir votre adresse email professionnelle et non celle de l'agence. Elle doit contenir un point entre votre prénom et nom.
                                                        </span>
                                                    } @else {
                                                        <span>
                                                            Merci de saisir une adresse email valide.
                                                        </span>
                                                    }
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="row mt-5 mb-5">
                                            <div class="col pt-1">
                                                <button class="btn btn-black mt-2 text-nowrap"
                                                        data-cy="submit"
                                                        name="button"
                                                        type="submit">Recevoir le lien de connexion
                                                </button>
                                            </div>
                                        </div>
                                        @if (error) {
                                            <div class="text-center mt-5 text-danger">
                                                Une erreur inconnue s'est produite. Merci de contacter notre assistance.
                                            </div>
                                        }
                                    </form>
                                } @else {
                                    <h2 class="text-dark py-5 my-5 text-center">
                                        Vous devez être invité pour utiliser la plateforme
                                    </h2>
                                }
                            </mat-card-content>
                        }
                    </mat-card>
                </div>
                <div class="col-12 col-md">
                    <div class="row h-100 gap-3">
                        <div class="col-12">
                            <mat-card class="h-100 card-white">
                                <mat-card-content class="pt-5 px-5">
                                    <h1><i aria-hidden="true" class="fas fa-landmark fa-2x"></i>
                                        <span class="big ms-2">ACCÈS DIRECTION ?</span>
                                    </h1>
                                    <p class="text-dark">
                                        Afin de vous connecter en tant qu'utilisateur siège ou directeur, merci de vous
                                        rapprocher de votre gestionnaire de compte Isifid ou de nous contacter par le
                                        chat.
                                    </p>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-12">
                            <mat-card class="h-100 text-white pt-5 pb-3 px-4 px-lg-5 helpCard">
                                <mat-card-content>
                                    <h1 class="big pb-3">BESOIN D’AIDE ?</h1>
                                    <p class="my-3">
                                        C’est par ici ! <i aria-hidden="true" class="fas fa-comments"></i>
                                    </p>
                                    <button (click)="crispService.toggleSupportChat()" class="py-1 px-5 mt-3"
                                            mat-raised-button>
                                        Support
                                    </button>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

