import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {DateService, Offer, Operation, UserService} from '@isifid/core';
import {GiftService} from '../../../shared/services/gift.service';
import {ExcelService} from '@isifid/reward';
import {GiftUserService} from '../../../shared/services/gift-user.service';
import {Router} from '@angular/router';
import {StatsService} from '../../../shared/services/stats.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {filter, map} from 'rxjs/operators';
import {concat, finalize, toArray} from 'rxjs';
import {OperationsService} from '../../../shared/services/operations.service';
import {MatPaginator} from '@angular/material/paginator';
import {OperationStat, StatsDisplay} from '../../admin/models/stats.model';

@Component({
    selector: 'app-consumers-tracking',
    templateUrl: './tracking.component.html',
    styles: `.min-w-130 {min-width: 130px;}`
})
export class TrackingComponent implements OnInit {
    loading: boolean = false;
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[];
    colNames: string[];
    branchList: string[] = [];
    expiredOperation: boolean = true;
    operations: Operation[] = [];
    trackingForm: FormGroup;

    private consumerStats: StatsDisplay[] = [];
    private operationStat: OperationStat;
    private allOperations: Operation[] = [];
    private activatedOperations: Operation[] = [];
    private offers: Array<Offer> = [];
    @ViewChild(MatSort) private sort: MatSort;
    @ViewChild(MatPaginator) private matPaginator: MatPaginator;

    constructor(
        readonly giftService: GiftService,
        private readonly excelService: ExcelService,
        private readonly formBuilder: FormBuilder,
        private readonly dateService: DateService,
        private readonly giftUserService: GiftUserService,
        private readonly router: Router,
        private readonly operationsService: OperationsService,
        private readonly statsService: StatsService,
        private readonly userService: UserService,
        private readonly _snackBar: MatSnackBar
    ) {
    }

    ngOnInit(): void {
        this.giftUserService.getGiftUser().pipe(filter(s => !!s))
            .pipe(
                map(s => ({...s, branchList: s.branchList ?? []}))
            )
            .subscribe(giftUser => {
                this.branchList = giftUser.branchList.map(s => this.giftService.convertBranchCodeToString(s)).filter(s => s);
            });
        this.displayedColumns = this.statsService.getStatsOperationsDisplayedColumns()[1];
        this.colNames = this.statsService.getStatsOperationsColNames()[1];
        this.initOperation();
    }

    hasRole(role: string): boolean {
        return this.userService.hasRole(role);
    }

    getStats(): void {
        if (this.trackingForm.invalid || this.loading) return;

        this.loading = true;
        this.dataSource = undefined;
        this.consumerStats = [];
        const filters = {
            start: this.dateService.computeDate(this.trackingForm.get('start').value, 'yyyy-MM-dd'),
            end: this.dateService.computeDate(this.trackingForm.get('end').value, 'yyyy-MM-dd', 1)
        };

        this.statsService.getByOperationId(this.trackingForm.get('operationId').value, [], filters)
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: stats => {
                    this.operationStat = stats;
                    this.consumerStats = this.statsService.formatStatsOperation(
                        stats.rewardStats, [], this.offers, [], this.trackingForm.controls.anonymizeConsumersData.value
                    );

                    // Filter by branchCode
                    this.consumerStats = this.consumerStats.filter(s => {
                        return this.trackingForm.controls.branchCode.value ?
                            this.trackingForm.controls.branchCode.value === s.branchCode :
                            true;
                    });
                    this.dataSource = new MatTableDataSource<unknown>(this.consumerStats);
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.matPaginator;
                },
                error: () => this._snackBar.open('Une erreur est survenue', 'X')
            });
    }

    download(): void {
        if (this.consumerStats.length === 0) return;
        const fileName = this.operationStat.operationName;
        const aoa = [
            this.statsService.getStatsOperationsColNames()[1],
            ...this.consumerStats.map(s => ([
                s.giftUserEmail, s.firstName, s.lastName, s.externalId, s.mobile, s.email, s.amount, s.rewardedAt, s.expireAt, s.label
            ])),
            '', '',
            ['Opération : ', fileName],
            ['Date début stats', this.statsService.convertDateForSpreadsheet(this.operationStat.startDate)],
            ['Date fin', this.statsService.convertDateForSpreadsheet(this.operationStat.endDate)]
        ];

        this.excelService.exportAsExcelFileAoA(
            [aoa],
            `${fileName}_${new Date().toISOString().substring(0, 10)}`,
            ['Stats']
        );
    }

    resetForm(): void {
        this.trackingForm.reset({
            start: this.dateService.computeDate( this.operations[0]?.startDate ?? new Date(), 'yyyy-MM-dd'),
            end: this.dateService.computeDate(new Date(), 'yyyy-MM-dd'),
            branchCode: this.branchList.length == 1 ? this.branchList[0] : '',
            operationId: this.operations[0]?.id,
            useAllOperations: {value: false, disabled: this.operations.length === 0 && this.allOperations.length > 0},
            anonymizeConsumersData: false
        });
    }

    private initOperation(): void {
        // Get all operations
        const operations = this.operationsService.operations;
        this.allOperations = operations.filter(o => o.operationType.name !== 'SPONSORSHIP' && (o.status === 'active' || o.status === 'expired'));
        this.activatedOperations = this.allOperations.filter(o => o.status === 'active');

        if (this.activatedOperations.length !== this.allOperations.length) this.expiredOperation = true;

        this.operations = this.activatedOperations;
        // if only expired operations disabled checkbox
        if (this.operations.length === 0 && this.allOperations.length > 0) {
            this.operations = this.allOperations;
            this._snackBar.open('Aucune opération active', 'X');
        }
        if (!this.operations && this.operations.length === 0) {
            this._snackBar.open('Aucune opération', 'X');
        } else {
            this.initForm();
        }

        if (this.operations.length) this.getOffers();
    }

    private initForm(): void {
        this.trackingForm = this.formBuilder.group({
            start: [
                this.dateService.computeDate( this.operations[0]?.startDate ?? new Date(), 'yyyy-MM-dd'),
                [Validators.required]
            ],
            end: [
                this.dateService.computeDate(new Date(), 'yyyy-MM-dd'),
                [Validators.required]
            ],
            operationId: [this.operations[0]?.id, Validators.required],
            branchCode: this.branchList.length == 1 ? this.branchList[0] : '',
            anonymizeConsumersData: false,
            useAllOperations: {value: false, disabled: this.operations.length === 0 && this.allOperations.length > 0}
        });
        this.trackingForm.controls.useAllOperations.valueChanges.subscribe(s => {
            this.operations = s ? this.allOperations : this.activatedOperations;
            this.trackingForm.controls.operationId.setValue(this.operations[0]?.id);
        });
    }

    private getOffers(): void {
        this.offers = [];
        const observers = this.operations.map(s => this.operationsService.getOffersByOperationId(s.id));
        concat(...observers)
            .pipe(toArray())
            .subscribe({
                next: offers => this.offers = offers.flat()
            });
    }
}
